"use client";
import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import { googlePublisherTagSizes } from '../../functions/global';

declare global {
  interface Window {
    googletag: any;
  }
  namespace googletag {
    namespace events {
      interface SlotOnloadEvent {
        slot: any;
      }
    }
  }
}

// Keep track of defined ad slots.
let adSlots: { [key: string]: any } = {};
type DefineAdSlotProps = { isHp?: boolean, bannerId: string, bannerClass: string };

if (typeof window !== 'undefined') {
  // Ensure we can interact with the GPT command array.
  window.googletag = window.googletag || { cmd: [] };
  const googlePublisherEnv = process.env.NEXT_PUBLIC_GOOGLE_ENV || "test"; //Valeurs possibles : "test" ou "prod".
  const article_id = '';
  const rubrique = '';
  const page_type = 'home';
  // Prepare GPT to display ads.
  if (window.googletag && window.googletag.cmd) {
    window.googletag.cmd.push(() => {
      // Enable SRA and services.
      window.googletag.pubads().enableSingleRequest();
      window.googletag.enableServices();
      window.googletag.pubads().setCentering(true);
      window.googletag.pubads().collapseEmptyDivs();
      window.googletag.pubads().setTargeting('article-id', article_id);
      window.googletag.pubads().setTargeting('rubrique', rubrique);
      window.googletag.pubads().setTargeting('page-type', page_type);
      window.googletag.pubads().setTargeting('env', googlePublisherEnv);
    });
  }
}

const DefineAdSlot = ({ isHp = false, bannerId, bannerClass }: DefineAdSlotProps) => {
  const pathname = usePathname();
  const mappings: any = googlePublisherTagSizes[bannerId as keyof typeof googlePublisherTagSizes].mapping;
  const sizes: any = googlePublisherTagSizes[bannerId as keyof typeof googlePublisherTagSizes].sizes;
  const adUnitChild = pathname === "/" ? "homepage" : "rg";
  const adUnitPath = "/" + process.env.NEXT_PUBLIC_GOOGLE_PUBLISHER_ID + "/" + process.env.NEXT_PUBLIC_GOOGLE_PUBLISHER_ADUNIT + "/" + adUnitChild;
  const [showAds, setShowAds] = useState(false);
  const [showAdsPlaceHolder, setShowAdsPlaceHolder] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (window.googletag && window.googletag.cmd) {
        window.googletag.cmd.push(() => {
          const adMapping = window.googletag.sizeMapping();
          Object.keys(mappings).forEach((breakpoint) => {
            const mapping: any = mappings[breakpoint];
            adMapping.addSize([Number(breakpoint), 0], mapping);
          });
          const bannerMapping = adMapping.build();
          const bannerSlot = window.googletag.defineSlot(adUnitPath, sizes, bannerId);
          if (bannerMapping !== null && bannerSlot !== null) {
            bannerSlot.defineSizeMapping(bannerMapping)
              .setTargeting('pos', bannerId)
              .addService(window.googletag.pubads());
            adSlots[bannerId] = bannerSlot;
            window.googletag.display(bannerSlot);
          }
        });
        window.googletag.cmd.push(() => {
          window.googletag.pubads().addEventListener("slotOnload", (event: googletag.events.SlotOnloadEvent) => {
            const slot = event.slot;
            if (slot.getSlotElementId() === "banner-atf") {
              setShowAds(true);
              setShowAdsPlaceHolder(false);
            }
          });
        });
      }
    }, 3500); // Délai de 3 secondes avant l'initialisation des publicités

    return () => {
      clearTimeout(timeoutId);
      if (window.googletag && window.googletag.cmd) {
        window.googletag.cmd.push(() => {
          if (adSlots[bannerId]) {
            window.googletag.destroySlots([adSlots[bannerId]]);
            delete adSlots[bannerId];
            if (bannerId === "banner-atf") {
              setShowAds(false);
              setShowAdsPlaceHolder(true);
            }
          }
        });
      }
    };
  }, [adUnitPath, bannerId, sizes, mappings, pathname]);

  return (
    <>
      {bannerId == "banner-atf" ? (
        <div className={'flex flex-col h-[120px] md:h-[250px]' + (isHp ? "" : " mb-4")}>
          <div className={`${bannerClass} ` + (!showAds ? 'hidden' : '')} id={bannerId}></div>
          <div className={`flex h-[100px] md:h-[250px] justify-center items-center w-full bg-gray-50 align-middle ` + (!showAdsPlaceHolder ? 'hidden' : '')}>
            <span>Publicité</span>
          </div>
        </div>
      ) : (
        <div className={bannerClass} id={bannerId}></div>
      )}
    </>
  );
}

export { DefineAdSlot };