export const formatPrix = (prix: number) => {
    return Intl.NumberFormat('fr-FR',
        {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
        }).format(prix)
}

export const formatPhoneNumber = (phoneNumber: string) => {
    // Supprimer tous les caractères non numériques
    const cleaned = phoneNumber.replace(/\D/g, '');

    // Déterminer le format en fonction de la longueur du numéro
    if (cleaned.length === 10) {
        // Format national (10 chiffres)
        return cleaned.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
    } else if (cleaned.length === 11 && cleaned.startsWith('1')) {
        // Format international (11 chiffres, commençant par 1)
        return cleaned.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 $2 $3 $4');
    } else if (cleaned.length === 12 && cleaned.startsWith('33')) {
        // Format international (12 chiffres, commençant par 33)
        return cleaned.replace(/(\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5 $6');
    } else {
        // Autres formats
        return phoneNumber;
    }
}

export const documentLabel = (type: string) => {
    switch (type) {
        case "texte_complet":
            return "Texte complet"
            break;
        case "ccv":
            return "Cahier des conditions des ventes";
            break;
        case "pv_descriptif":
            return "PV de description";
            break;
        case "diagnostic":
            return "Diagnostic et urbanisme";
            break;
        case "autres":
            return "Autres";
            break;
    }
}

export const googlePublisherTagSizes = {
    "banner-atf": {
        sizes: [[728, 90]],
        mapping: {
            1025    : [[1,1],[1000,250],[1000,200],[1000,90],[970,200],[970,90],[728,90]]
            ,768    : [[728,90]]
            ,300    : [[320,50],[320,100]]
        }
    }
    ,"pave-atf": {
        sizes: [[300, 600],[300, 250]],
        mapping: {
            1025    : [[300,250],[300,600]]
            ,768    : [[300,250],[300,600]]
            ,300    : [[300,250]]
        }
    }
    ,"pave-mtf" : {
        sizes: [[300, 600],[300, 250]],
        mapping: {
            1025    : [[300,250],[300,600]]
            ,768    : [[300,250],[300,600]]
            ,300    : [[300,250]]
        }
    }
}
export const sanitizeString = (str: string) =>
{
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '').toLowerCase();
}

export function calculerCompteRebour(dateVente: any) {
    const dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);

    const dateVenteObj = new Date(dateVente);
    dateVenteObj.setHours(0, 0, 0, 0);

    const diffTime = dateVenteObj.getTime() - dateNow.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
}

export function calculerDecompte(dateVente: Date): string | null {
    const compteRebour = calculerCompteRebour(dateVente);
    if (compteRebour < 0) {
        const dateNow = new Date();
        dateNow.setHours(0, 0, 0, 0);

        const dateVenteObj = new Date(dateVente);
        dateVenteObj.setHours(0, 0, 0, 0);

        const timeDiff = dateVenteObj.getTime() - dateNow.getTime();
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        let decompteText = "Il y a %d %s";
        let nombre = 0;
        let texteNombre = "";

        // Calculer les années, mois, jours
        const years = dateVenteObj.getFullYear() - dateNow.getFullYear();
        const months = dateVenteObj.getMonth() - dateNow.getMonth();
        const days = diffDays;

        if (years > 0) {
            nombre = years;
            texteNombre = "an" + (years > 1 ? "s" : "");
        } else if (months > 0) {
            nombre = months;
            texteNombre = "mois";
        } else {
            nombre = days;
            texteNombre = "jour" + (days > 1 ? "s" : "");
        }

        return decompteText.replace('%d', nombre.toString()).replace('%s', texteNombre);
    }
    return null;
}

export const isEmailValid = (email: string) => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(email);
};

export const resultatAdjudicationValue = 
{
    nonRequise : "Non requise",
    enchereDeserte : "Enchères désertes",
    surenchere : "Surencherisseur adjudicataire",
};

export const resultatAdjudicationLotValue = 
{
    nonRequise : "Non requise",
    enchereDeserte: "Enchères désertes",
    surenchere: "Surencherisseur adjudicataire",
};
  